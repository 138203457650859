import React from "react"
import { Grid } from "@mui/material"
import Bookay from "./Bookay"
import Kyoquiz from "./Kyoquiz"
import Timeline from "./Timeline"
import Elo from "./Elo"
import BXC from "./BXC"
import Codeplace from "./Codeplace"
import ThData from "./ThData"
import Momentum from "./Momentum"

const getSections = (isLarge) => [
  <Grid
    id="Timeline"
    item
    xs={12}
    sx={{ height: "100vh", backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Timeline isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Momentum isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Bookay isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Kyoquiz isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <ThData isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Elo isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <Codeplace isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4] }}>
    <BXC isLarge={isLarge} />
  </Grid>,
  <Grid item xs={12} sx={{ backgroundColor: (theme) => theme.palette.blue[0], color: (theme) => theme.palette.blue[4], pt: "50vh" }} />,
]

const Sections = ({ isLarge }) => {
  return <>{getSections(isLarge).map((s) => s)}</>
}

export default Sections

import React from "react"
import { Grid } from "@mui/material"
import KyoquizLogo from "../Logos/kyoquiz.png"
import AppOverview from "../Screenshots/Kyoquiz/overview.png"
import QuizImg from "../Screenshots/Kyoquiz/quiz.png"
import LeaderImg from "../Screenshots/Kyoquiz/leader.png"
import Section from "./Section"

const Kyoquiz = ({ isLarge }) => {
  const text = [
    "Kyoquiz is an online quiz to learn the syllabus of Kyokushin Karate.",
    "I always wanted a tool to help me learn the japanese terminology and gain more knowledge about my sport. Besides making this app for my Kyokushin club, I have written a book about the examination kihon (basic syllabus in karate).",
    "With the mobile web app, karate students can easily learn new karate terminology and confidently pass their next belt examination.",
  ]

  const AppOverviewComp = (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={AppOverview} alt="Project-Overview" />
      </Grid>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={QuizImg} alt="Project-Overview" />
      </Grid>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={LeaderImg} alt="Project-Overview" />
      </Grid>
    </Grid>
  )

  return (
    <Section
      id="Kyoquiz"
      LogoSrc={KyoquizLogo}
      // linkUrl="https://kyoquiz.team"
      // linkToDisplay="kyoquiz.team"
      FirstImg={AppOverviewComp}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      linkColor="#FBDE40"
      isLarge={isLarge}
    />
  )
}

export default Kyoquiz

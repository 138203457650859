import React from "react"
import Section from "./Section"
import MomentumImage from "../Logos/Momentum.png"
import MomentumImage2 from "../Logos/Momentum2.png"
import BMBFLogo from "../Logos/BMBF.png"
import IDiALogo from "../Logos/idiaLogo.png"
import MMLogo from "../Logos/momentumLong.png"

const Momentum = ({ isLarge }) => {
  const text = [
    "Momentum is a new way to implement habits in your life.",
    "You make a bet with your friends to follow a habit or challenge. If you fail, you choose what charity your bet is donated to. If you win, you have implemented a new habit!",
    "The app is launching in Google Play Store and Apple App Store in 2025.",
  ]

  const boldText =
    "Momentum got accepted into Batch 8 of the Startup Accelerator 'Kickstart' of IDiA and is sponsored by the BMBF (Federal Ministry of Education and Research)"

  const HeaderLogo = (
    <>
      <img style={{ borderRadius: "4px", width: "45%" }} src={MomentumImage} alt="BMBF-Logo" />
      <img style={{ borderRadius: "4px", marginLeft: "5%", width: "45%" }} src={MomentumImage2} alt="IDiA-Logo" />
    </>
  )
  const BMBFLogoComp = (
    <>
      <img style={{ borderRadius: "4px", width: isLarge ? "30%" : "45%" }} src={BMBFLogo} alt="BMBF-Logo" />
      <img style={{ borderRadius: "4px", width: isLarge ? "30%" : "45%" }} src={IDiALogo} alt="IDiA-Logo" />
    </>
  )

  return (
    <Section
      id="Momentum"
      LogoSrc={MMLogo}
      FirstImg={HeaderLogo}
      BottomImg={BMBFLogoComp}
      boldText={boldText}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      isLarge={isLarge}
    />
  )
}

export default Momentum

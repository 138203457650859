import React from "react"
import BookayLogo from "../Logos/Bookay.png"
import BMBFLogo from "../Logos/BMBF.png"
import IDiALogo from "../Logos/idiaLogo.png"
import AppOverview from "../Screenshots/Bookay/app-overview.png"
import Section from "./Section"

const Bookay = ({ isLarge }) => {
  const text = [
    "Bookay is a schoolbook trading platform for high school students in Germany.",
    "With several schools using the platform since 2021, Bookay saves students and parents yearly up to 50% of the aquisition price for school books, which can be as high as 700 € in some schools.",
    "It offers customization for every school. With licences and modules, Bookay can be customized to the needs of every school.",
  ]

  const boldText =
    "Bookay got accepted into Batch 11 of the Startup Accelerator 'Kickstart' of IDiA and is sponsored by the BMBF (Federal Ministry of Education and Research)"

  const AppOverviewComp = <img style={{ borderRadius: "4px", width: "100%" }} src={AppOverview} alt="Project-Overview" />
  const BMBFLogoComp = (
    <>
      <img style={{ borderRadius: "4px", width: isLarge ? "30%" : "45%" }} src={BMBFLogo} alt="BMBF-Logo" />
      <img style={{ borderRadius: "4px", width: isLarge ? "30%" : "45%" }} src={IDiALogo} alt="IDiA-Logo" />
    </>
  )
  // const IDiALogoComp = <img style={{ borderRadius: "4px", width: "30%" }} src={IDiALogo} alt="IDiA-Logo" />

  return (
    <Section
      id="Bookay"
      LogoSrc={BookayLogo}
      linkUrl="https://bookay-buecher.de"
      linkToDisplay="bookay-buecher.de"
      FirstImg={AppOverviewComp}
      BottomImg={BMBFLogoComp}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      linkColor="#98e39c"
      isLarge={isLarge}
      boldText={boldText}
    />
  )
}

export default Bookay

import React from "react"
import PlaneusLogo from "../Logos/Planeus.png"
import Dashboard from "../Screenshots/ThData/Dashboard.jpg"
import Gantt from "../Screenshots/ThData/Gantt.jpg"
import Section from "./Section"

const ThData = ({ isLarge }) => {
  const text = [
    "Since 2022 I am working part time for th data GmbH, a software company focusing on production planning software.",
    "I help developing Planeus, a React and .NET web app for production planning.",
    // "As my starting project at th data, I have also developed an internal program used to track the working time of employees.",
  ]

  const AppOverviewComp = (
    <>
      <img style={{ filter: "brightness(95%)", borderRadius: "4px", width: isLarge ? "45%" : "100%" }} src={Dashboard} alt="Project-Dashboard" />
      <img
        style={{
          filter: "brightness(95%)",
          borderRadius: "4px",
          width: isLarge ? "45%" : "100%",
          marginLeft: isLarge ? "32px" : "0px",
          marginTop: isLarge ? "0px" : "16px",
        }}
        src={Gantt}
        alt="Project-Dashboard"
      />
    </>
  )

  return (
    <Section
      id="Planeus"
      LogoSrc={PlaneusLogo}
      linkUrl="https://planeus-solutions.com"
      linkToDisplay="planeus-solutions.com"
      FirstImg={AppOverviewComp}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      linkColor="#fff"
      isLarge={isLarge}
    />
  )
}

export default ThData

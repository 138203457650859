import React from "react"
import Section from "./Section"
import EloImage from "../Logos/eloimage2.jpg"

const Elo = ({ isLarge }) => {
  const text = [
    "The Elo system is a widely used quantitative skill rating system in zero-sum games. It is the standard rating system in chess and many PvP video games.",
    "This project is an attempt to apply the Elo system to the world of competitive sports. The goal is to create a system in which every athlete in a particular sport holds a certain amount of points which reflect this atheletes ability / skill.",
    "By assigning a numerical value to reflect the skill of every athlete, it becomes easier to compare athletes, even if their careers are divided by large timespans or weight divisions, etc.",
    "Right now I am analyzing datasets of MMA fights using Python and Jupyter Notebook.",
  ]

  const EloImageComp = <img style={{ borderRadius: "4px", width: "100%" }} src={EloImage} alt="Elo" />

  return (
    <Section
      title="Elo System Application to Competitive Sports"
      wip
      FirstImg={EloImageComp}
      id="Elo"
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      isLarge={isLarge}
    />
  )
}

export default Elo

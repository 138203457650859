import React from "react"
import { Grid } from "@mui/material"
import BXCLogo from "../Logos/bxc.png"
import IndexImg from "../Screenshots/BXC/Index.png"
import PortfolioImg from "../Screenshots/BXC/Portfolio.png"
import FachImg from "../Screenshots/BXC/Fach.png"
import Section from "./Section"

const BXC = ({ isLarge }) => {
  const text = [
    "BookXChange (BXC) was the MVP to the school book trading platform which became later Bookay.",
    "This ugly mobile-only web app was written in even uglier PHP but was used for 2 years by several schools before being superceded by Bookay.",
    // "Although being a minimalistic mobile-only monolithic web app written completely in PHP and styled with vanilla CSS, it was used for 2 years by several schools before being superceded by Bookay.",
    "Not to be confused with www.bookxchange.com, a similar project but not related.",
  ]

  const AppOverviewComp = (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={IndexImg} alt="Landing-Page" />
      </Grid>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={FachImg} alt="Select-Subject" />
      </Grid>
      <Grid item xs={4}>
        <img style={{ borderRadius: "4px", width: "100%", filter: "brightness(85%)" }} src={PortfolioImg} alt="Dasboard" />
      </Grid>
    </Grid>
  )

  return (
    <Section
      finished
      id="BXC"
      FirstImg={AppOverviewComp}
      LogoSrc={BXCLogo}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      isLarge={isLarge}
    />
  )
}

export default BXC

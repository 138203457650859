import React from "react"
import LaunchIcon from "@mui/icons-material/Launch"
import { Typography, Link, Paper } from "@mui/material"

const Section = ({
  LogoSrc,
  title,
  linkUrl,
  linkToDisplay,
  FirstImg,
  text,
  backgroundColor,
  fontColor,
  linkColor,
  id,
  wip,
  finished,
  isLarge,
  boldText,
  BottomImg,
}) => {
  return (
    <Paper
      id={id}
      elevation={10}
      sx={{
        backgroundColor,
        color: fontColor,
        p: isLarge ? 5 : 2,
        mx: isLarge ? 5 : 1,
        my: isLarge ? 5 : 2,
      }}>
      {LogoSrc ? (
        <Typography paragraph sx={{ display: "flex", alignItems: "center" }}>
          <img src={LogoSrc} style={{ marginRight: "8px" }} height={isLarge ? "60px" : "40px"} alt="Project-Logo" />{" "}
          {finished && <i>(finished project)</i>}
        </Typography>
      ) : (
        <Typography paragraph variant="h4">
          {title} {false && wip && <i>(In Progress)</i>}
        </Typography>
      )}

      {linkUrl && (
        <Link
          variant="h6"
          paragraph
          href={linkUrl}
          target="_blank"
          sx={{ fontWeight: "bold", color: linkColor, textDecoration: "none", display: "flex", alignItems: "center" }}>
          {linkToDisplay}
          <LaunchIcon fontSize="small" sx={{ ml: 1 }} />
        </Link>
      )}
      {FirstImg && (
        <Typography paragraph sx={{ my: 4 }}>
          {FirstImg}
        </Typography>
      )}
      {text.map((t) => (
        <Typography variant="body1" paragraph>
          {t}
        </Typography>
      ))}
      {boldText && (
        <Typography
          variant={isLarge ? "h6" : "body"}
          paragraph
          sx={{ textAlign: "center", p: 4, fontWeight: "bold", backgroundColor: (theme) => theme.palette.blue[0] }}>
          {boldText}
        </Typography>
      )}
      {BottomImg && (
        <Typography paragraph sx={{ mt: 4 }}>
          {BottomImg}
        </Typography>
      )}
    </Paper>
  )
}

export default Section

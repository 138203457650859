import React from "react"
import { Typography, Box } from "@mui/material"
import DTLogo from "../DTLogo"

const Timeline = ({ isLarge }) => {
  return isLarge ? (
    <>
      <Typography variant="h3" textAlign="left" paragraph m={5}>
        DT | Portfolio
      </Typography>
      <Typography variant="h6" paragraph ml={5}>
        frontend, backend, weekend dev
      </Typography>
    </>
  ) : (
    <Box sx={{ mt: "60%" }}>
      <DTLogo isLarge={isLarge} />
      <Typography paragraph variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 4 }}>
        DT | Portfolio
      </Typography>
      <Typography paragraph variant="h6" sx={{ fontWeight: "bold", textAlign: "center" }}>
        {/* Portfolio & Projects */}
        frontend, backend, weekend dev
      </Typography>
    </Box>
  )
}

export default Timeline

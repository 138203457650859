import React, { useState, useEffect } from "react"
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material"
import Sections from "./components/Sections/Sections"
import Header from "./components/Header"

const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  if (el) {
    const { top, left, bottom, right } = el.getBoundingClientRect()
    const { innerHeight, innerWidth } = window
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
  }
  return undefined
}

const projects = ["Timeline", "Bookay", "Kyoquiz", "BXC", "Elo", "Codeplace", "Planeus", "Momentum"]

const getCurrentProject = (set) => {
  projects.forEach((p) => {
    if (elementIsVisibleInViewport(document.getElementById(p), true)) {
      set(p)
    }
  })
}

const App = () => {
  const [currentProject, setCurrentProject] = useState("Timeline")
  const [scrollTop, setScrollTop] = useState(0)

  const theme = useTheme()
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"))

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    getCurrentProject(setCurrentProject)
    if (scrollTop < 120) {
      // if you scroll fast to the top, async checking of scrollTop thinks that 1st project is current
      setCurrentProject("Timeline")
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return (
    <Box sx={{ width: largeDisplay ? "100%" : "100%" }}>
      <Grid container>
        <Grid item xs={12} md={12 - 12 / 1.6}>
          <Header isLarge={largeDisplay} currentProject={currentProject} />
        </Grid>
        <Grid container item xs={12} md={12 / 1.6}>
          <Sections isLarge={largeDisplay} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default App
